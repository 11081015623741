/* @import './home.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  @media (min-width: 1280px) {
    ::-webkit-scrollbar {
      @apply w-2 bg-[#F1F1F1];
    }

    ::-webkit-scrollbar-thumb {
      @apply bg-[#C1C1C1] ah:hover:bg-[#A8A8A8] ah:active:bg-[#787878];
    }
  }



  @font-face {
    font-family: "SourceHanSansCN";
    src: url("/src/font/SourceHanSansCN-Medium.woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "SourceHanSansCN";
    src: url("/src/font/SourceHanSansCN-Medium.woff");
    font-weight: 500;
    font-style: medium;
    font-display: swap;
  }

  @font-face {
    font-family: "SourceHanSansCN";
    src: url("/src/font/SourceHanSansCN-Bold.woff");
    font-weight: 700;
    font-style: bold;
    font-display: swap;
  }

  body {
    @apply min-w-[23.4375rem] overflow-x-hidden scroll-auto text-text selection:bg-main  selection:text-white xs:text-sm;
    /* @apply min-w-[23.4375rem] overflow-x-hidden scroll-auto text-text selection:bg-main selection:text-white xs:text-sm; */
  }
  form {
    @apply m-0
  }

  input,
  textarea {
    @apply w-full h-11 px-4 resize-none bg-inherit border placeholder:text-[#8D8D8D] 
    /* @apply w-full h-11 px-4 resize-none bg-inherit border placeholder:text-[#8D8D8D] outline-main */
  }
  iframe {
    @apply max-h-full max-w-full;
  }

  .w {
    @apply mx-auto w-full max-w-[85.625rem]  lg:max-w-[80rem] md:max-w-[64rem] sm:max-w-[48rem] xs:max-w-[40rem];
  }

  .base-container {
    @apply relative mx-auto max-w-[120rem];
  }

 .form-input,
  .form-textarea {
    @apply focus:!border-main-0.05  focus:!ring-[#ddd] focus:!outline-0 focus:!outline-offset-0 !border-[#ddd];
    /* @apply block w-full resize-none !border-border !px-4 !py-2.5 !ring-0 placeholder:!text-[#CECECE] focus:!border-none xs:!text-sm; */
  }

  .form-checkbox {
    @apply !h-5 !w-5 !outline-0 !ring-0 checked:!border-main checked:!bg-main;
  }

  .form-select {
    @apply w-full !border-border !px-4 !py-2.5 !outline-0 !ring-0 focus:!border-main xs:!text-sm;
  }
.swiper-button-next,
  .swiper-button-prev {
    @apply c-active;
  } 

  .swiper-pagination {
    @apply static flex flex-wrap items-center justify-center gap-3 md:gap-2;
  }

  .swiper-pagination .swiper-pagination-bullet {
    @apply c-active !m-0 h-3 w-3 rounded-full bg-[#CECECE] opacity-100 transition md:h-2 md:w-2;
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    @apply bg-main;
  }

  .bi {
    @apply inline-block fill-current align-middle !leading-[0];
  }

/* fangaode ↓*/
  /* tab */
  .tab-pane {
    @apply hidden
  }

  .tab-pane.active {
    @apply block
  }

  /* swiper */
  .swiper-wrapper{
    @apply h-auto
  }

  /* swiper control */
  .about_page .swiper-button-prev,
 .about_page .swiper-button-next {
    @apply w-14 h-14 -mt-7 bg-white border border-border shadow rounded-full active:scale-95 md:w-12 md:h-12 xs:w-10 xs:h-10
  }
  .proDetail_page .swiper-button-prev::after,
  .proDetail_page .swiper-button-next::after,
 .about_page .swiper-button-prev::after,
 .about_page .swiper-button-next::after {
    @apply text-[1rem] text-main
  }

  .swiper-button-next.swiper-button-disabled, 
  .swiper-button-prev.swiper-button-disabled {
    @apply opacity-50
  } 

  /* swiper pagination */
 .swiper-pagination{
    @apply static flex justify-center items-center mt-5
  }

  .swiper-pagination .swiper-pagination-bullet{
    @apply w-[0.625rem] h-[0.625rem] !mx-[0.3125rem] bg-main opacity-50 xs:w-2 xs:h-2
  }

  .swiper-pagination .swiper-pagination-bullet-active{
    @apply opacity-100
  }  

 .thumb-hover{
    @apply transition-all group-hover:opacity-80 group-hover:scale-110
  }

/* fangaode↑*/
}

/* fangaode css ↑*/

.doc--active {
  height: 100vh;
  overflow: hidden;
}

.scrollFadeInUp,
.scrollFadeInLeft,
.scrollFadeInRight,
.scrollFadeZoomIn,
.scrollFadeZoomOut {
  will-change: transform;
}

.static_important{
  position: static !important;
}

.after_hidden::after {
  display: none;
}
/* fnagaode css */
@layer components {
  /* search */
  .c-search {
    @apply fixed left-0 right-0 top-0 bottom-0 z-50 hidden justify-center items-center bg-text/30 backdrop-blur-sm
  }

  .c-search.active {
    @apply flex
  }

  /* c-sidebar */
  .c-sidebar{
    @apply fixed right-[1.875rem] bottom-[1.875rem] z-30 space-y-2 hidden xs:right-3 xs:bottom-3
  }

  .c-sidebar.active{
    @apply block
  }

  .c-sidebar__item{
    @apply bg-white p-5 rounded-full shadow cursor-pointer active:scale-95 xs:p-3
  }

  /* video */
  .c-video {
    @apply fixed left-0 right-0 top-0 bottom-0 z-50 hidden justify-center items-center bg-black/70 backdrop-saturate-[180%] backdrop-blur-[1.25rem] overflow-y-auto
  }

  .c-video.active {
    @apply flex
  }
  .c-btn2 {
    @apply c-active inline-block min-w-[11.25rem] cursor-pointer border border-main bg-main px-8 py-3.5 text-center text-white hover:border-title hover:bg-title md:min-w-0 md:px-6;
  }
  .c-btn{
    @apply flex justify-center items-center max-w-[10rem] w-full h-12 px-3 space-x-[0.625rem] bg-[#003e79] text-white rounded-sm whitespace-nowrap hover:bg-main active:scale-95
  }
  .c-btn-water{
    @apply flex justify-center items-center max-w-[10rem] w-full h-12 px-3 space-x-[0.625rem] bg-main text-white rounded-sm whitespace-nowrap hover:bg-[#0094f0] active:scale-95
  }
  /* .c-btn-water{
    @apply flex justify-center items-center max-w-[10rem] w-full h-12 px-3 space-x-[0.625rem] bg-[#0094f0] text-white rounded-sm whitespace-nowrap hover:bg-main active:scale-95
  } */
  .c-btn--white{
    @apply bg-white text-main hover:text-white
  }

  .c-title__wrap{
    @apply mx-auto text-center
  }

  .c-title{
    @apply text-5xl md:text-4xl xs:text-3xl text-title font-semibold
  }

  .c-title__desc{
    @apply text-text-sub mt-4 xs:mt-3
  }
  
  /* 分页 */
  .c-pagination {
    @apply flex justify-center
  }

  .c-pagination__list {
    @apply flex flex-wrap gap-5
  }

  .c-pagination__item {
    @apply flex justify-center items-center gap-2 min-w-[2.5rem] xs:min-w-[2rem] h-10 xs:h-8 px-2 bg-white border border-border text-sm text-text-sub hover:border-main hover:text-main
  }

  .c-pagination__item.c-pagination__item--current {
    @apply bg-main border-main text-white pointer-events-none
  }

  .has-baffle{
    @apply relative
  }

  .has-baffle::after{
    @apply content-[''] absolute right-0 top-0 z-10 w-10 h-full bg-gradient-to-r from-transparent to-border pointer-events-none
  }

  .has-baffle--top::after{
    @apply w-full h-10 bg-gradient-to-t
  }

  .c-summary{
    @apply flex items-start cursor-pointer relative list-none before:w-4 before:h-0.5 after:w-0.5 after:h-4 after:mr-[0.4375rem] group-open:before:opacity-0
  }

  .c-summary::before,
  .c-summary::after{
    @apply absolute right-0 top-1/2 -translate-y-1/2 bg-main transition-all group-open:rotate-90 group-open:bg-main
  }
}

/* 提取成组件样式 */
@layer utilities {
  /* 多行显示... */
  .text-overflow-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .text-overflow-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .text-overflow-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .desktop-menu__list {
    @apply flex items-center h-[6.25rem] 
    /* @apply flex items-center h-[6.25rem] space-x-8 lg:space-x-6 */
  }

  .desktop-menu__item{
    @apply relative h-full flex items-center px-3 hover:after:w-full lg:!px-[0.6rem] mini_pc:!px-4
  }
  .desktop-menu__item::after{
    @apply content-[''] absolute left-0 bottom-0 w-0 h-1 bg-main transition-all
  }

  .desktop-menu__item.current-menu-item {
    @apply after:w-full bg-main/10
  }

  .desktop-menu__link{
    @apply block whitespace-nowrap truncate hover:text-main
  }

  .desktop-menu__list--sub {
    @apply absolute left-0 top-full w-[15.625rem] p-2 bg-white shadow rounded-md text-tiny animate-fadeInDown !hidden
  }

  /* hover */
  .desktop-menu__item:hover > .desktop-menu__list--sub {
    @apply !block
  }

  .desktop-menu__list--sub .desktop-menu__item{
    @apply block px-0 after:hidden
  }

  .desktop-menu__list--sub .desktop-menu__link{
    @apply px-4 rounded-md leading-10 any-hover:hover:bg-main any-hover:hover:text-white
  }

  /* current state */
  .desktop-menu__item.current-menu-item-ancestor,
  .desktop-menu__item.current-menu-item-parent,
  .desktop-menu__item.current-menu-item {
    @apply after:w-full bg-main/10
  }

  /* mobile menu */
  .mobile-menu {
    @apply fixed left-0 right-0 top-0 bottom-0 z-50 w-screen h-screen bg-text/80 backdrop-saturate-[180%] backdrop-blur-[1.25rem] hidden animate-fadeIn
  }

  .mobile-menu.active {
    @apply block
  }

  .mobile-menu__list {
    @apply absolute left-0 top-0 bottom-0 w-[18.75rem] xs:w-full py-10 px-3 bg-white divide-y divide-border overflow-y-auto animate-fadeInDown
  }

  .mobile-menu__list--sub {
    @apply pl-3 text-tiny divide-y hidden animate-fadeInDown
  }

  .mobile-menu__item {
    @apply relative
  }

  .mobile-menu__item.open > .mobile-menu__list--sub{
    @apply block
  }

  .mobile-menu__item.open > .mobile-menu__arrow{
    @apply rotate-180
  }

  .mobile-menu__arrow{
    @apply absolute right-0 top-0 aspect-square w-12 flex justify-center items-center cursor-pointer transition-all
  }

  .mobile-menu__link {
    @apply block leading-[3rem] truncate
  }

  .current-menu-item-ancestor>a,
  .current-menu-item-parent>a,
  .current-menu-item>a {
    @apply !text-main
  }

  .current-menu-item-ancestor>ul,
  .current-menu-item-parent>ul,
  .current-menu-item>ul {
    @apply block
  }

  .current-menu-item-ancestor>.arrow,
  .current-menu-item-parent>.arrow,
  .current-menu-item>.arrow {
    @apply rotate-180
  }

  .banner-swiper-pagination {
    @apply m-0
  }

  .banner-swiper-pagination .swiper-pagination-bullet {
    @apply mb-[.625rem]
    /* @apply bg-white */
    /* @apply bg-main */
  }

  .about-point {
    @apply flex items-start
  }

  .about-point::before {
    @apply content-[''] flex-shrink-0 inline-block w-4 h-4 mt-1 bg-center bg-cover bg-no-repeat bg-[url(/lib/icon/img_about_content_point.svg)]
  }

  .apparel-projects-item{
    @apply absolute
  }

  .apparel-projects-item__container {
    @apply border-2 border-main shadow rounded-full overflow-hidden animate-bounce-slow
  }

  .apparel-projects-item__link{
    @apply absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center p-5 bg-main/80 opacity-0 transition-all group-hover:opacity-100 md:opacity-100
  }

  .apparel-projects-item__title {
    @apply text-white text-center text-xl md:text-base xs:text-tiny
  }

  .apparel-projects-item--1{
    @apply left-0
  }

  .apparel-projects-item--2{
    @apply left-[12.53731%] top-0
  }
  .apparel-projects-item--3{
    @apply left-[15.52239%] bottom-0 xs:left-[10%] xs:bottom-[10%]
  }
  .apparel-projects-item--4{
    @apply left-[37.08955%] top-[5.25rem]
  }
  .apparel-projects-item--5{
    @apply bottom-5 right-[33.58209%] xs:bottom-1/4
  }
  .apparel-projects-item--6{
    @apply right-[19.40299%] xs:right-0
  }
  .apparel-projects-item--7{
    @apply top-1 right-[8.20896%]
  }
  .apparel-projects-item--8{
    @apply bottom-0 right-0
  }

  .apparel-projects-item--1 .apparel-projects-item__container{
    @apply w-[8.75rem] h-[8.75rem] md:w-[6.5625rem] md:h-[6.5625rem] xs:w-24 xs:h-24 animation-delay-100
  }
  .apparel-projects-item--2 .apparel-projects-item__container{
    @apply w-[13.75rem] h-[13.75rem] md:w-[10.3125rem] md:h-[10.3125rem] xs:w-32 xs:h-32 animation-delay-200
  }
  .apparel-projects-item--3 .apparel-projects-item__container{
    @apply w-40 h-40 md:w-[7.5rem] md:h-[7.5rem] xs:w-28 xs:h-28 animation-delay-300
  }
  .apparel-projects-item--4 .apparel-projects-item__container{
    @apply w-[12.5rem] h-[12.5rem] md:w-[9.375rem] md:h-[9.375rem] xs:w-28 xs:h-28 animation-delay-400
  }
  .apparel-projects-item--5 .apparel-projects-item__container{
    @apply w-40 h-40 md:w-[7.5rem] md:h-[7.5rem] xs:w-24 xs:h-24 animation-delay-100
  }
  .apparel-projects-item--6 .apparel-projects-item__container{
    @apply w-[8.75rem] h-[8.75rem] md:w-[6.5625rem] md:h-[6.5625rem] xs:w-28 xs:h-28 animation-delay-400
  }
  .apparel-projects-item--7 .apparel-projects-item__container{
    @apply w-[8.75rem] h-[8.75rem] md:w-[6.5625rem] md:h-[6.5625rem] xs:w-24 xs:h-24 animation-delay-300
  }
  .apparel-projects-item--8 .apparel-projects-item__container{
    @apply w-[12.5rem] h-[12.5rem] md:w-[9.375rem] md:h-[9.375rem] xs:w-32 xs:h-32 animation-delay-200
  }
}

@layer utilities {
  .title-2xl {
    @apply text-5xl font-bold text-title xl:text-[2.5rem] md:text-4xl xs:text-3xl;
  }

  .title-xl {
    @apply text-4xl font-bold text-title md:text-3xl xs:text-2xl;
  }

  .title-lg {
    @apply text-[2rem] font-bold leading-[2.375rem] text-title md:text-2xl xs:text-xl;
  }

  .title-md {
    @apply text-2xl font-bold text-title md:text-xl xs:text-lg;
  }

  .title-sm {
    @apply text-xl font-bold text-title md:text-lg xs:text-base;
  }

  .title-xs {
    @apply text-lg font-bold text-title md:text-base xs:text-sm;
  }

  .c-active {
    @apply transition active:scale-95 active:duration-100;
  }

  .obj-cover {
    @apply h-full w-full object-cover;
  }

  .thumb-hover {
    @apply relative bg-white transition ah:group-hover:scale-110;
  }

  .rotate-x-180 {
    transform: rotateX(180deg);
  }
}

.doc--active {
  @apply h-screen overflow-hidden;
}

#noty_layout__center {
  @apply w-full max-w-xl;
}

.noty_bar {
  @apply !mx-3;
}

.noty_body {
  @apply text-center text-base;
}

/* pagination */
.c-pagination {
  @apply text-center text-sm;
}

.c-pagination__list {
  @apply flex flex-wrap justify-center gap-3;
}

.c-pagination__item.current .c-pagination__link {
  @apply pointer-events-none border-main bg-main text-white;
}

.c-pagination__link {
  @apply block rounded border bg-white px-3.5 py-2 xs:px-3 xs:py-1.5 ah:hover:border-main ah:hover:text-main;
}

/* desktop menu start */
.desktop-menu__list {
  @apply relative flex h-full items-center justify-between whitespace-nowrap;
}

.desktop-menu__item {
  @apply relative flex h-full items-center px-4 ;
}

.desktop-menu__item.has-super-menu {
  @apply static;
}

.desktop-menu__item .desktop-super-menu {
  @apply absolute inset-x-0 top-full mx-auto hidden w-full max-w-[91.25rem] animate-fadeInDown bg-white text-text shadow;
}

.desktop-menu__item.has-super-menu:hover .desktop-super-menu {
  @apply block;
}

.desktop-menu__item.has-super-menu .desktop-menu__link {
  @apply pointer-events-none;
}

.desktop-menu__item .desktop-menu__link {
  @apply relative block;
  /* @apply relative block text-white; */
}

.desktop-menu__link::after {
  @apply absolute -bottom-2 left-1/2 h-0.5 w-0 -translate-x-1/2 bg-white transition-all content-[''];
}

.desktop-menu__arrow {
  @apply ml-1.5 text-xs text-white transition;
}

.desktop-menu__item .desktop-menu__list {
  @apply absolute -left-4 top-full hidden h-auto min-w-[15rem] max-w-[18.75rem] animate-fadeInDown bg-white p-2 text-sm text-title shadow;
}

.desktop-menu__item .desktop-menu__list .desktop-menu__list {
  @apply -top-2 left-full animate-fadeInRight;
}

.desktop-menu__item:hover .desktop-menu__link::after {
  @apply w-full;
}

.desktop-menu__item:hover > .desktop-menu__list {
  @apply block;
}

.desktop-menu__item:hover > .desktop-menu__arrow {
  @apply rotate-x-180;
}

.desktop-menu__item .desktop-menu__item {
  @apply block h-auto p-0 hover:bg-main hover:text-white;
}

.desktop-menu__item .desktop-menu__item .desktop-menu__link {
  @apply line-clamp-1 block px-2 leading-10 text-inherit after:hidden;
}
/* desktop menu end */

/* mobile menu */
.mobile-menu__list {
  @apply divide-y overflow-y-auto px-5 text-base font-bold;
}

.mobile-menu__list--sub {
  @apply hidden animate-fadeInDown divide-y border-t pl-2 text-sm;
}

.mobile-menu__item {
  @apply relative;
}

.mobile-menu__item.active > .mobile-menu__list--sub {
  @apply block;
}

.mobile-menu__item.active > .mobile-menu__arrow {
  @apply rotate-x-180;
}

.mobile-menu__arrow {
  @apply absolute right-0 top-0 flex aspect-square w-12 cursor-pointer items-center justify-center text-xs transition;
}

.mobile-menu__link {
  @apply line-clamp-1 block leading-[3rem];
}
.home-banner-swiper-button {
  --swiper-navigation-size: 1rem;
  color: #006db1;
  font-size: 10px;
}

.home-banner-swiper-prev::after,
.home-banner-swiper-next::after {
  content: "";
}
.home-banner-swiper-prev {
  left: 30px;
  top: -9px;
}
.home-banner-swiper-next {
  right: 0px;
  top: -9px;
}
.home-banner-swiper-pagination {
  @apply !justify-start;
}
.home-banner-swiper-pagination .swiper-pagination-bullet {
  @apply !mr-[30px] h-[6px] w-[6px];
}

.home-banner-swiper-pagination .swiper-pagination-bullet-active {
  @apply bg-white outline outline-[2px] outline-offset-8  outline-white;
}

.footer-form__input {
  @apply border-none text-text;
}

.footer-form__submit {
  @apply cursor-pointer border-none outline-none;
  /* @apply cursor-pointer border-none bg-main; */
}

:is(.current-menu-item, .current-menu-parent, .current-menu-ancestor) > a {
  @apply text-main;
}

:is(
    .current-menu-item .desktop-menu__link,
    .current-menu-parent .desktop-menu__link,
    .current-menu-ancestor .desktop-menu__link
  ) {
  @apply after:w-full;
}

.footer-menu-icon {
  @apply hidden transition xs:block;
}

.active .footer-menu-icon {
  @apply rotate-x-180;
}

:is(.pop-form__input, .pop-form__select, .pop-form__textarea) {
  @apply !py-3.5 !text-base xs:!text-sm;
}

.pop-form__textarea {
  @apply h-[12.5rem];
}

/* header nav */
.social img {
  @apply mr-[.375rem] inline-block h-5 w-5;
}
header .mobile_nav_i::before {
  @apply !font-bold;
}
header .header-r span {
  /* @apply ml-5; */
}
header .pc_menu_ul a{
  /* @apply block h-full; */
}
/* header menu */
.menu .left ul li ,.menu .left ul li a{
  /* @apply mr-[3.125rem]; */
  /* @apply h-full */
}
.menu .right img {
  @apply inline-block w-[1.875rem] cursor-pointer;
}
/* module_banner */
.module_banner .mask {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 109, 177, 0) 63.02%,
    #006db1 100%
  );
}

/* module_about */

.module_about .left_img,
.module_about .left .mask {
  -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
}

.module_about .right_img,
.module_about .right .mask {
  -webkit-clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
}
@media (max-width: 1280px) {
  .module_about .left_img,
  .module_about .left .mask {
    -webkit-clip-path: none;
    clip-path: none;
  }
  .module_about .right_img,
  .module_about .right .mask {
    -webkit-clip-path: none;
    clip-path: none;
  }
}
/* ↑给图片定义media，md的时候不要裁剪 */

.module_about .right:hover .mask,
.module_about .right p,
.module_about .right p ~ div,
.module_about .right:hover ~ .left p,
.module_about .right:hover ~ .left p ~ div {
  @apply invisible;
}
.handle_flex_col {
  @apply sm:flex-col sm:px-[1.25rem];
}
.handle_w {
  @apply sm:mx-8 sm:w-screen xs:mx-8 xs:w-screen;
}
.handle_w_full {
  @apply sm:w-full xs:w-full;
}
@media (max-width: 1024px) {
  .module_about .right:hover .mask,
  .module_about .right p,
  .module_about .right p ~ div,
  .module_about .right:hover ~ .left p,
  .module_about .right:hover ~ .left p ~ div,
  .module_about .right:hover ~ .left p ~ a {
    @apply visible;
  }
}
/* 以上是对右边的效果 */
.module_about .right:hover {
  /* 注意要把hover的元素放在前一位css层上面,这是移动到right对左边的效果√ */
  @apply right-0 z-10 translate-x-0 transition-all;
}
.right:hover ~ .left {
  /* @apply left-[-780px] z-0 transition; */
  @apply z-0 translate-x-[-770px] transition-all md:translate-x-0;
}
.module_about .right:hover p,
.module_about .right:hover p ~ div,
.module_about .right:hover p ~ a,
.right:hover ~ .left .mask {
  @apply visible;
}
.right:hover ~ .left h3 {
  @apply !ml-[48.75rem];
}
/* module_trusted  */
.module_trusted .swiper-wrapper {
  transition-timing-function: linear !important;
}
/* modele_latest */
.tabItem:nth-child(2) {
  @apply border-0;
}

.latest-swiper-button {
  --swiper-navigation-size: 1rem;
  font-weight: 700;
  color: #161c29;
}
/* .latest-button:hover .latest-swiper-button {
  color: #006db1;
} */
.latest-swiper-button-prev {
  left: 25px;
}
.latest-swiper-button-next {
  right: 25px;
}
/* module  */
.text-second {
  @apply text-base text-nav-text sm:text-sm;
}
/* module_hot */
/* .module_hot .imgList > div {
  
} */


.module_hot button {
  @apply mx-auto mb-[.625rem] block h-12 w-[17.5rem] rounded text-white;
}

/* module_blog */
.module_title {
  /* @apply text-[2.5rem]  font-bold leading-[3.75rem] text-title; */
  @apply text-[2.5rem] font-bold leading-[3.75rem] xs:leading-[2.8rem] text-title sm:text-[2.25rem];
}
.tag {
  @apply mb-8 mt-4 text-sm text-main;
}
/* .big-card-title{
  @
} */
/* footer */
.bg-footer input {
  @apply border-0 text-white focus:ring-0;
  background-color: #515661;
}
.footer-h3 {
  @apply mb-[1rem] text-base font-bold text-white;
}
.footer-h3 ~ div {
  @apply grid grid-cols-2 text-footer-gray;
}
.footer-h3 ~ div a {
  @apply mb-[0.375rem] pr-3 block;

}
.footer-h3 ~ div a:hover {
  @apply text-main;
}
footer .bg-footer-hi form .form-div{
@apply gap-[.625rem]
}
footer .bg-footer-hi form .submit  input{
  @apply mt-[1.5rem] h-[2.5rem] w-[22.5rem] xs:!w-full !bg-main
}
footer .top-foorer{
  @apply max-w-[52.5rem] lg:max-w-[50.625rem] sm:max-w-none
}
.under-bar a > img {
  @apply !mr-[.625rem] inline-block h-10 w-10 xs:h-8 xs:w-8;
}
.under-bar a:last-child:not(.logo) > img {
  @apply !mr-0;
}
.under-bar .logo img {
  @apply !mr-[1.875rem] inline-block !h-auto !w-auto xs:!mx-0;
}
.under-bar div>span {
  @apply ml-[1.25rem] inline-block border-b border-divider text-sm xs:ml-0 xs:mr-2;
}
.logo-box .logo-img{
  @apply w-[12.375rem]
}


/* 其他融入的页面fangao的 */
.prod-text {
  @apply text-[.875rem]
}

.aboutPage-swiper .swiper-slide{
  @apply flex justify-center;
}

/* 修改菜单头的当前页面问题 */
.desktop-menu__item.active::after{
width: 100%;
}
.desktop-menu__item.active>a{
  color:#006db1;
}
/* home 提取 */
.home-case{
  @apply w-full sm:px-[1.25rem] bg-white py-[6.25rem] sm:pb-0 sm:pt-[2.5rem]
}
.home-case .img-list{
  @apply grid-cols-hot_img grid gap-[.625rem] md:grid-cols-2  sm:grid-cols-2 xs:grid-cols-1
}
.home-case .img-list .img-item{
  @apply obj-cover transition 
}
.home-case .img-list a.item{
  @apply relative block w-full h-full
}
.home-case .img-list a.item .mark{
  @apply w-full h-full top-0 left-0 hidden absolute inset-0  animate-fadeIn items-center justify-center bg-title/70
}
.home-case .img-list a.item:hover .img-item{
  @apply scale-110
}
.home-case .img-list a.item:hover .mark{
  @apply flex
}
.home-case .img-list a.item .mark p{
  @apply text-white p-5
}
.p-5.blog-swiper{
  @apply !p-5
}